/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    OWRewardMaxType,
    OWRewardMaxTypeFromJSON,
    OWRewardMaxTypeFromJSONTyped,
    OWRewardMaxTypeToJSON,
    OWRewardMilestone,
    OWRewardMilestoneFromJSON,
    OWRewardMilestoneFromJSONTyped,
    OWRewardMilestoneToJSON,
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeFromJSONTyped,
    OWRewardTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface OWQuestBody
 */
export interface OWQuestBody {
    /**
     * 
     * @type {string}
     * @memberof OWQuestBody
     */
    game?: string;
    /**
     * The title of the reward.
     * @type {string}
     * @memberof OWQuestBody
     */
    name: string;
    /**
     * The description of the reward.
     * @type {string}
     * @memberof OWQuestBody
     */
    description?: string;
    /**
     * 
     * @type {OWRewardType}
     * @memberof OWQuestBody
     */
    rewardType: OWRewardType;
    /**
     * The amount of reward that can be claimed.
     * @type {number}
     * @memberof OWQuestBody
     */
    rewardAmount: number;
    /**
     * The maximum amount of reward that can be claimed.
     * @type {number}
     * @memberof OWQuestBody
     */
    rewardMax?: number;
    /**
     * 
     * @type {OWRewardMaxType}
     * @memberof OWQuestBody
     */
    rewardMaxType?: OWRewardMaxType;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWQuestBody
     */
    rewardCategory: OWLogCategory;
    /**
     * 
     * @type {OWGameEventType}
     * @memberof OWQuestBody
     */
    eventType: OWGameEventType;
    /**
     * The target amount of progress to reach to claim the reward.
     * @type {number}
     * @memberof OWQuestBody
     */
    target: number;
    /**
     * If quest is monthly, the user stats for it resets every month.
     * @type {boolean}
     * @memberof OWQuestBody
     */
    isMonthlyQuest?: boolean;
    /**
     * 
     * @type {Array<OWRewardMilestone>}
     * @memberof OWQuestBody
     */
    milestones?: Array<OWRewardMilestone>;
}

export function OWQuestBodyFromJSON(json: any): OWQuestBody {
    return OWQuestBodyFromJSONTyped(json, false);
}

export function OWQuestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWQuestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'game': !exists(json, 'game') ? undefined : json['game'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rewardType': OWRewardTypeFromJSON(json['rewardType']),
        'rewardAmount': json['rewardAmount'],
        'rewardMax': !exists(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMaxType': !exists(json, 'rewardMaxType') ? undefined : OWRewardMaxTypeFromJSON(json['rewardMaxType']),
        'rewardCategory': OWLogCategoryFromJSON(json['rewardCategory']),
        'eventType': OWGameEventTypeFromJSON(json['eventType']),
        'target': json['target'],
        'isMonthlyQuest': !exists(json, 'isMonthlyQuest') ? undefined : json['isMonthlyQuest'],
        'milestones': !exists(json, 'milestones') ? undefined : ((json['milestones'] as Array<any>).map(OWRewardMilestoneFromJSON)),
    };
}

export function OWQuestBodyToJSON(value?: OWQuestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'rewardType': OWRewardTypeToJSON(value.rewardType),
        'rewardAmount': value.rewardAmount,
        'rewardMax': value.rewardMax,
        'rewardMaxType': OWRewardMaxTypeToJSON(value.rewardMaxType),
        'rewardCategory': OWLogCategoryToJSON(value.rewardCategory),
        'eventType': OWGameEventTypeToJSON(value.eventType),
        'target': value.target,
        'isMonthlyQuest': value.isMonthlyQuest,
        'milestones': value.milestones === undefined ? undefined : ((value.milestones as Array<any>).map(OWRewardMilestoneToJSON)),
    };
}


