import {ButtonHTMLAttributes, ReactElement, useCallback, useEffect, useState} from "react";
import {
    Address,
    BusinessesApi,
    HandoffOptions, MenuOrder, MenuOrderItem,
    UsersApi, UtilsApi,
    ValidateDeliveryDistanceResponse,
} from "@devour/client";
import {useDispatch, useSelector} from "react-redux";
import {IStore} from "@/redux/defaultStore";
import {
    addError,
    decrementLoading,
    incrementLoading,
    toggleOrderHandoff,
    updateCurrentUser,
    toggleStickyFooter, addMenuOrderError,
    addDevourIqMenuOrderError,
} from "@/redux/meta/metaActions";
import {Navigate, useParams} from "react-router";
import RestaurantMenus from "./components/RestaurantMenus";
import RestaurantPageTopBar from "./components/RestaurantPageTopBar";
import getConfig from "../../utils/getConfig";
import {MdArrowBack} from "react-icons/md";
import {Link} from "react-router-dom";
import classNames from "classnames";
import DevourCart from "../../components/sideBar/DevourCart";
import FrameButton from "../../components/buttons/FrameButton";
import {FaShoppingCart} from "react-icons/fa";
import {RestaurantContext} from "./context/RestaurantContext";
import {AiFillHeart, AiOutlineHeart} from "react-icons/ai";
import {isDesktop, isMobile} from "react-device-detect";
import RestaurantMenuPageTopBarSkeleton
    from "../../components/skeletons/restaurantMenuPage/RestaurantMenuPageTopBarSkeleton";
import RestaurantMenuPageHeaderSkeleton
    from "../../components/skeletons/restaurantMenuPage/RestaurantMenuPageHeaderSkeleton";
import {useRestaurant} from "@/hooks/useRestaurant";
import RestaurantHeaderDefault from "@/components/restaurants/RestaurantHeaderDefault";
import {useMenuOrder} from "@/hooks/menuOrder/useMenuOrder";
import MenuOrderErrorModal from "@/pages/restaurants/components/MenuOrderErrorModal";
import DevourIqMenuOrderErrorModal from "./components/DevourIqMenuOrderModal";
import RestaurantPageRequiredAddressAutoPanel
    from "@/pages/restaurants/components/RestaurantPageRequiredAddressAutoPanel";
import {useUpdateMenuOrderItems} from "@/hooks/menuOrder/useUpdateMenuOrderItems";
import _ from "lodash";
import {useCreateMenuOrderItems} from "@/hooks/menuOrder/useCreateMenuOrderItems";
import {useQueryClient} from "@tanstack/react-query";
import {getUniqueIDForMenuOrderItem} from "@/utils/restaurantPageHelperFunctions";
import { useScopedGate, ENABLE_LOOT_SHOP_ACCESS_GATE_KEY } from "@/hooks/useScopedGate";


export const DEVOUR_RESTAURANT_ID = "674a30b9b53b4c9f4aee6587";

function RestaurantPage(): ReactElement {
    const dispatch = useDispatch();
    const {value: isLootShopEnabled} = useScopedGate(ENABLE_LOOT_SHOP_ACCESS_GATE_KEY);
    const {restaurantId, placeId} = useParams<{ restaurantId: string; placeId: string; }>();
    const queryRestaurant = useRestaurant(restaurantId, placeId);
    const [
        mobileSearchMode,
        setMobileSearchMode,
    ] = useState<boolean>(false);
    const [
        validateDeliveryDistanceResponse,
        setValidateDeliveryDistanceResponse,
    ] = useState<ValidateDeliveryDistanceResponse>(null);
    const [
        showCartPanel,
        setShowCartPanel,
    ] = useState(false);
    const [
        activeMenuItemId,
        setActiveMenuItemId,
    ] = useState<string>("");
    const isSidebarModalActive = useSelector((store: IStore) => store.metaStore.sidebarModalActive);
    const menuOrdersRefresh = useSelector((store: IStore) => store.metaStore.menuOrdersRefresh);
    const menuOrders = useSelector((store: IStore) => store.metaStore.menuOrders);
    const fullToken = useSelector((store: IStore) => store.authStore.fullToken);
    const currentUser = useSelector((store: IStore) => store.metaStore.currentUser);
    const existingMenuOrderId = menuOrders ? menuOrders[restaurantId] : undefined;
    const {data: menuOrder, refetch: refetchMenuOrder, isLoading: isMenuOrderLoading} = useMenuOrder(existingMenuOrderId);
    const [showAddressModal, setShowAddressModal] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const handoff = useSelector((store: IStore) => store.metaStore.handoff);
    const isDigitalStore = queryRestaurant.data?.handoffOptions.every(h => h === HandoffOptions.DIGITAL);
    const isDevourStore = DEVOUR_RESTAURANT_ID === restaurantId;
    const {
        mutateAsync: updateMenuOrderItems,
    } = useUpdateMenuOrderItems({
        menuOrder: menuOrder,
        menuOrderErrorModal: true,
        backgroundCallback: true,
    });
    const {
        mutateAsync: createMenuOrderItems,
    } = useCreateMenuOrderItems({
        restaurantId: queryRestaurant?.data?.id,
    });

    const [
        menuOrderItemsManager,
        setMenuOrderItemsManager,
    ] = useState<{ [key: string]: MenuOrderItem }>();

    const debounceLoadData = useCallback(_.debounce((manager: { [key: string]: MenuOrderItem }) => {
        if (!menuOrder) {
            return createMenuOrderWithManager(manager);
        }
        return updateMenuOrderItems({
            orderItems: Object.keys(manager).map(k => manager[k]),
        });

    }, 250), [menuOrder, queryRestaurant?.data]);

    /**
     * If no place id on page load, set this flag, so that when we DO get the placeid from redux after the user sets one without leaving the page,
     * we can persist the prompt (then it gets removed if they refresh or come back).
     *
     */
    useEffect(() => {
        if (placeId && queryRestaurant.data) {
            void validateDeliveryDistance();
        } else if (queryRestaurant.data?.handoffOptions.includes(HandoffOptions.DELIVERY) && !placeId) {
            setShowAddressModal(true);
        }
    }, [placeId, queryRestaurant.data]);

    useEffect(() => {
        void refetchMenuOrder();
    }, [menuOrdersRefresh]);

    useEffect(() => {
        if (menuOrder && menuOrderItemsManager === undefined) {
            // Populate the items manager on first load
            setMenuOrderItemsManager(menuOrder.orderItems.reduce((acc, curr) => {
                const id: string = getUniqueIDForMenuOrderItem(curr);
                acc[id] = curr;
                return acc;
            }, {}));
        }
    }, [menuOrder]);

    useEffect(() => {
        if (menuOrder?.validationErrorMessage) {
            dispatch(addMenuOrderError({
                errorMessage: menuOrder.validationErrorMessage,
                restaurantId: menuOrder.business,
            }));
            queryRestaurant.refetch();
        }
    }, [menuOrder?.validationErrorMessage]);

    useEffect(() => {
        if (menuOrder?.devourIqNotification) {
            dispatch(addDevourIqMenuOrderError({
                errorMessage: menuOrder.devourIqNotification,
                restaurantId: menuOrder.business,
            }));
            queryRestaurant.refetch();
        }
    }, [menuOrder?.devourIqNotification]);

    useEffect(() => {
        // When the cart is open, hide the bottom sticky footer on mobile and tablets
        if (!isDesktop) {
            if (showCartPanel) {
                dispatch(toggleStickyFooter(false));
            } else {
                dispatch(toggleStickyFooter(true));
            }
        }

        return () => {
            if (!isDesktop) {
                dispatch(toggleStickyFooter(true));
            }
        };
    }, [showCartPanel]);

    async function createMenuOrderWithManager(manager: { [key: string]: MenuOrderItem }): Promise<MenuOrder> {
        dispatch(incrementLoading());
        try {
            const findAddressInBook = currentUser?.user?.addresses.find(a => a.placeId === placeId);
            let address: Address;
            if (placeId) {
                address = await new UtilsApi().getAddressFromPlaceId({
                    placeId: placeId,
                });
            } else {
                address = queryRestaurant?.data?.address;
            }
            const newHandoff = queryRestaurant?.data?.handoffOptions.includes(handoff) ? handoff : queryRestaurant?.data?.handoffOptions[0];
            return createMenuOrderItems({
                address: address,
                orderItems: Object.keys(manager || {}).map(k => manager[k]),
                deliveryNotes: findAddressInBook?.deliveryInstructions,
                deliveryHandoffInstructions: findAddressInBook?.handoffInstructions,
                handoff: newHandoff,
            });
        } catch (e) {
            dispatch(await addError(e));
            await queryClient.invalidateQueries({queryKey: ["restaurant-menu", queryRestaurant?.data?.id]});
        } finally {
            dispatch(decrementLoading());
        }
    }

    function handleUpdateMenuOrderItemsManager(items: MenuOrderItem[], clearCart: boolean = false): void {
        if (clearCart) {
            setMenuOrderItemsManager({});
            void debounceLoadData({});
            return;
        }

        const itemManager = menuOrderItemsManager || {};
        for (const item of items) {
            const id: string = getUniqueIDForMenuOrderItem(item);
            if (item.quantity < 0) {
                // delete the item from the manager
                delete itemManager[id];
            } else {
                // Update the item in the manager otherwise
                itemManager[id] = item;
            }
        }
        setMenuOrderItemsManager(itemManager);
        void debounceLoadData(itemManager);
    }

    async function validateDeliveryDistance(): Promise<void> {
        dispatch(incrementLoading());

        try {
            const res = await new BusinessesApi(getConfig(fullToken)).validateDeliveryDistance({
                id: placeId,
                restaurant: restaurantId,
            });

            setValidateDeliveryDistanceResponse(res);
            if (!res.canDeliver && queryRestaurant.data?.handoffOptions.includes(HandoffOptions.PICKUP)) {
                dispatch(toggleOrderHandoff(HandoffOptions.PICKUP));
            }
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    /**
     * Favorite / unfavorite this restaurant.
     */
    async function onFavorite(): Promise<void> {
        dispatch(incrementLoading());

        try {
            await new UsersApi(getConfig(fullToken)).favoriteBusiness({
                businessId: restaurantId,
            });
            const userRes = await new UsersApi(getConfig(fullToken)).getProfile();
            dispatch(updateCurrentUser(userRes));
        } catch (e) {
            dispatch(await addError(e));
        } finally {
            dispatch(decrementLoading());
        }
    }

    function toggleShowCartPanel(): void {
        setShowCartPanel(s => !s);
    }

    const sumQuantity: number = Object.keys(menuOrderItemsManager || {})
        .map(k => menuOrderItemsManager[k].quantity)
        .reduce((acc, curr) => acc + curr, 0) || 0;

    function onCloseAddressModal(): void {
        setShowAddressModal(false);
    }

    if (!isLootShopEnabled && isDevourStore) {
        return <Navigate to={"/restaurants"} replace />;
    }


    return (
        <RestaurantContext.Provider value={{
            restaurantId: restaurantId,
            activeMenuItemId,
            setActiveMenuItemId,
            menuOrderId: existingMenuOrderId,
            isDigitalStore,
            isDevourStore,
        }}>
            <MenuOrderErrorModal
                updateMenuOrderItemManager={handleUpdateMenuOrderItemsManager}
            />
            <DevourIqMenuOrderErrorModal/>
            {fullToken && <RestaurantPageRequiredAddressAutoPanel
                isOpen={showAddressModal}
                onClose={onCloseAddressModal}
                restaurant={queryRestaurant.data}
            />}
            <div
                className={classNames("restaurant-page", {
                    "is-mobile-search": mobileSearchMode,
                    "is-digital": isDigitalStore,
                })}
            >
                <DevourCart
                    show={showCartPanel}
                    toggle={toggleShowCartPanel}
                    updateMenuOrderItemManager={handleUpdateMenuOrderItemsManager}
                    menuOrderItemsManager={menuOrderItemsManager}
                />

                {isMenuOrderLoading
                    ? <RestaurantMenuPageTopBarSkeleton/>
                    : <RestaurantPageTopBar
                        toggleShowCartPanel={toggleShowCartPanel}
                        sumQuantity={sumQuantity}
                    />
                }

                {!queryRestaurant.data
                    ? <RestaurantMenuPageHeaderSkeleton/>
                    : <div className="restaurant-page_header">
                        {!isDevourStore && <Link
                            className="restaurant-page_header_back"
                            to={placeId
                                ? `/restaurants/search/${placeId}`
                                : "/restaurants"}
                        >
                            <MdArrowBack/>
                        </Link>}
                        {currentUser?.user && !isDevourStore && !currentUser.user.favorites?.includes(restaurantId) &&
                            <button
                                className="restaurant-page_header_favorite-add"
                                title="Add to saved list"
                                onClick={onFavorite}
                            >
                                <AiOutlineHeart/>
                            </button>
                        }
                        {currentUser?.user && !isDevourStore && currentUser.user.favorites?.includes(restaurantId) &&
                            <button
                                className="restaurant-page_header_favorite-remove"
                                title="Remove from saved list"
                                onClick={onFavorite}
                            >
                                <AiFillHeart/>
                            </button>
                        }
                        <div className="restaurant-page_header_image">
                            <img
                                src={queryRestaurant.data?.headerImage?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                                alt={queryRestaurant.data?.name}
                            />
                        </div>
                        <div className="restaurant-page_logo-container restaurant-page_section-margin">
                            <div className="restaurant-page_header_logo">
                                <img
                                    src={queryRestaurant.data?.icon?.url || `${import.meta.env.VITE_CDN_URL}/images/placeholderitem.webp`}
                                    alt={queryRestaurant.data?.name}
                                />
                            </div>
                        </div>
                    </div>

                }

                <div className="restaurant-page_content">

                    {!queryRestaurant.data || queryRestaurant.data?.isOpen
                        ? <RestaurantMenus
                            mobileSearchMode={mobileSearchMode}
                            setMobileSearchMode={setMobileSearchMode}
                            validateDeliveryDistanceResponse={validateDeliveryDistanceResponse}
                            placeId={placeId}
                            toggleShowCartPanel={toggleShowCartPanel}
                            updateMenuOrderItemManager={handleUpdateMenuOrderItemsManager}
                            menuOrderItemsManager={menuOrderItemsManager}
                        />
                        : <>
                            <RestaurantHeaderDefault
                                data={queryRestaurant.data}
                                placeId={placeId}
                                validateDeliveryDistanceResponse={validateDeliveryDistanceResponse}
                            />
                            {isDesktop && <hr/>}
                            {queryRestaurant.data?.isOpen === false &&
                                <div className="restaurant-page_closed">
                                    <img
                                        src={`${import.meta.env.VITE_CDN_URL}/images/restaurant-closed-clock.webp`}
                                        alt="restaurant-closed-icon"
                                    />
                                    <div className="restaurant-page_closed_title">
                                        Sorry, we're closed!
                                    </div>
                                    <div className="restaurant-page_closed_description">
                                        <p>
                                            This restaurant is closed right now. Please check back later.
                                        </p>
                                    </div>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            {sumQuantity > 0 && !isSidebarModalActive && isMobile &&
                <div className="restaurant-page_cart-button">
                    <FrameButton
                        <ButtonHTMLAttributes<HTMLButtonElement>>
                        onClick={toggleShowCartPanel}
                        color="purple"
                        size="normal"
                        forwardProps={{type: "button"}}
                        className={classNames("restaurant-page_view-cart-button-rounded", {
                            "hide-cart-button": showCartPanel,
                        })}
                    >
                        <div className="restaurant-page_view-cart-button">

                            <FaShoppingCart/>

                            <div>
                                View Cart
                            </div>

                            <div className="restaurant-page_quantity">
                                {sumQuantity}
                            </div>

                        </div>

                    </FrameButton>
                </div>
            }
        </RestaurantContext.Provider>
    );
}

export default RestaurantPage;
