/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Base,
    BaseFromJSON,
    BaseFromJSONTyped,
    BaseToJSON,
    OWGameEventType,
    OWGameEventTypeFromJSON,
    OWGameEventTypeFromJSONTyped,
    OWGameEventTypeToJSON,
    OWLogCategory,
    OWLogCategoryFromJSON,
    OWLogCategoryFromJSONTyped,
    OWLogCategoryToJSON,
    OWRewardMaxType,
    OWRewardMaxTypeFromJSON,
    OWRewardMaxTypeFromJSONTyped,
    OWRewardMaxTypeToJSON,
    OWRewardMilestone,
    OWRewardMilestoneFromJSON,
    OWRewardMilestoneFromJSONTyped,
    OWRewardMilestoneToJSON,
    OWRewardType,
    OWRewardTypeFromJSON,
    OWRewardTypeFromJSONTyped,
    OWRewardTypeToJSON,
    TimeStamps,
    TimeStampsFromJSON,
    TimeStampsFromJSONTyped,
    TimeStampsToJSON,
} from './';

/**
 * Collection contains the rewards that can be claimed.
 * @export
 * @interface OWReward
 */
export interface OWReward {
    /**
     * 
     * @type {string}
     * @memberof OWReward
     */
    game?: string;
    /**
     * The title of the reward.
     * @type {string}
     * @memberof OWReward
     */
    name: string;
    /**
     * The description of the reward.
     * @type {string}
     * @memberof OWReward
     */
    description?: string;
    /**
     * 
     * @type {OWRewardType}
     * @memberof OWReward
     */
    rewardType: OWRewardType;
    /**
     * The amount of reward that can be claimed.
     * @type {number}
     * @memberof OWReward
     */
    rewardAmount: number;
    /**
     * The maximum amount of XP that can be claimed for the 1hr time quest.
     * @type {number}
     * @memberof OWReward
     */
    rewardMax?: number;
    /**
     * 
     * @type {OWRewardMaxType}
     * @memberof OWReward
     */
    rewardMaxType?: OWRewardMaxType;
    /**
     * 
     * @type {OWLogCategory}
     * @memberof OWReward
     */
    rewardCategory: OWLogCategory;
    /**
     * 
     * @type {OWGameEventType}
     * @memberof OWReward
     */
    eventType: OWGameEventType;
    /**
     * The target amount of progress to reach to claim the reward.
     * @type {number}
     * @memberof OWReward
     */
    target: number;
    /**
     * If quest is monthly, the user stats for it resets every month.
     * @type {boolean}
     * @memberof OWReward
     */
    isMonthlyQuest?: boolean;
    /**
     * 
     * @type {Array<OWRewardMilestone>}
     * @memberof OWReward
     */
    milestones?: Array<OWRewardMilestone>;
    /**
     * 
     * @type {string}
     * @memberof OWReward
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof OWReward
     */
    createdAt: number;
    /**
     * 
     * @type {number}
     * @memberof OWReward
     */
    updatedAt: number;
}

export function OWRewardFromJSON(json: any): OWReward {
    return OWRewardFromJSONTyped(json, false);
}

export function OWRewardFromJSONTyped(json: any, ignoreDiscriminator: boolean): OWReward {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'game': !exists(json, 'game') ? undefined : json['game'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'rewardType': OWRewardTypeFromJSON(json['rewardType']),
        'rewardAmount': json['rewardAmount'],
        'rewardMax': !exists(json, 'rewardMax') ? undefined : json['rewardMax'],
        'rewardMaxType': !exists(json, 'rewardMaxType') ? undefined : OWRewardMaxTypeFromJSON(json['rewardMaxType']),
        'rewardCategory': OWLogCategoryFromJSON(json['rewardCategory']),
        'eventType': OWGameEventTypeFromJSON(json['eventType']),
        'target': json['target'],
        'isMonthlyQuest': !exists(json, 'isMonthlyQuest') ? undefined : json['isMonthlyQuest'],
        'milestones': !exists(json, 'milestones') ? undefined : ((json['milestones'] as Array<any>).map(OWRewardMilestoneFromJSON)),
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
    };
}

export function OWRewardToJSON(value?: OWReward | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'game': value.game,
        'name': value.name,
        'description': value.description,
        'rewardType': OWRewardTypeToJSON(value.rewardType),
        'rewardAmount': value.rewardAmount,
        'rewardMax': value.rewardMax,
        'rewardMaxType': OWRewardMaxTypeToJSON(value.rewardMaxType),
        'rewardCategory': OWLogCategoryToJSON(value.rewardCategory),
        'eventType': OWGameEventTypeToJSON(value.eventType),
        'target': value.target,
        'isMonthlyQuest': value.isMonthlyQuest,
        'milestones': value.milestones === undefined ? undefined : ((value.milestones as Array<any>).map(OWRewardMilestoneToJSON)),
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
    };
}


